<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10">Tutorial Penggunaan Aplikasi</h5>
                        </div>
                        <div class="row">
                            <div class="col-md-12" style="margin-top: -10px;">
                                <button v-for="(item, index) in data_category" :key="index" class="mt-2 btn rounded m-r-10 m-t-10" :class="activeTabs == item ? 'btn-primary':'btn-outline-primary' " v-on:click="changeTab(item)" type="button">{{item == 'Semua Kategori' ? item:'Tutorial '+item}}</button>
                                <div class="input-group m-t-10">
                                    <input type="text" autocomplete="off" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan kata kunci..." v-model="keywords" v-on:keyup.enter="getData">
                                    <div class="input-group-append">
                                        <button v-on:click="getData" class="btn waves-effect waves-light btn-primary btn-icon btn-msg-send" type="button"><i
                                                class="fa fa-search"></i> Cari </button>
                                    </div>
                                </div>                                    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div style="margin-top:-10px !important" class="row" v-if="dataFetch">
            <div class="col-lg-4" v-for="(item,index) in [1,2,3,4,5,6]" :key="index">
                <div class="card d-block border rounded-lg p-3 m-t-10">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="mr-3 w-100">
                            <div class="d-flex align-items-center">
                                <div class="w-100">
                                    <div class="skeleton-bar text-center">
                                        <div class="sb-sm mb-2"></div>
                                        <hr/>
                                        <div class="sb-lg sb-thin mb-2"></div>
                                        <div class="sb-lg sb-thin mb-4"></div>
                                        <div class="sb-lg"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div style="margin-top:-10px !important" class="row" v-if="!dataFetch">
            <div class="col-lg-4" v-for="(item, index) in dataList" :key="index">
                <div class="card border">
                    <img v-if="item.cover" class="img-fluid card-img-top" :src="item.cover" alt="cover">
                    <img v-if="!item.cover" class="img-fluid card-img-top" src="assets/images/cover-video.png" alt="cover">
                    <div class="card-body d-flex flex-column">
                        <h5 class="card-title text-short">
                            <a data-toggle="modal" data-target="#modalView" href="#" v-on:click="goToView(item.content)">{{item.title}}</a>
                        </h5>
                        <div v-if="item.description" class="d-flex align-items-center justify-content-between pb-2 mb-2 border-bottom">
                            <div class="mr-3">{{ item.description }}</div>
                        </div>
                        <p v-if="item.created_at" class="card-text">
                            <small v-if="!item.updated_at">Dibuat pada: {{item.created_at}}</small>
                            <small v-if="item.updated_at">Terakhir diperbarui: {{item.updated_at}}</small>
                        </p>
                        <div  class="d-grid mb-0">
                            <button data-toggle="modal" data-target="#modalView" v-on:click="goToView(item.content)" type="button" class="btn btn-outline-primary rounded btn-block">
                                <i class="fa fa-play"></i> Putar Video
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <nav v-if="dataList.length > 0" aria-label="navigation" style="margin-top:-15px !important">
                <hr/>
                <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
            </nav>

            <div v-if="emptyState">
                <div class="card border p-3">
                    <empty-state></empty-state>
                </div>
            </div>
        </div>

        <div class="modal" id="modalView" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered modal-lg">
                <div class="modal-content" v-if="fetchModal">
                    <p class="text-center mb-3 mt-3">Loading...</p>
                </div>

                <div class="modal-content" v-if="!fetchModal">
                    <div v-if="!dataDetail.content">
                        <p class="text-center mb-3 mt-3">Video tidak tersedia</p>
                    </div>
                    <vue-plyr v-if="dataDetail.content">
                        <div class="plyr__video-embed">
                            <iframe
                            :src="dataDetail.content"
                            allowfullscreen
                            allowtransparency
                            allow="autoplay"
                            ></iframe>
                        </div>
                    </vue-plyr>
                    <button type="button" class="btn btn-light btn-block" data-dismiss="modal" v-on:click="closeModalView">Tutup</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'

    export default {
        name: 'AppTutorial',
        metaInfo: {
            title: 'Tutorial Aplikasi',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                activeTabs      : 'Semua Kategori',
                data_category   : ["Semua Kategori", "CPNS", "Kedinasan", "SNBT", "PPPK", "AKM", "English Academy", "CBT", "Polri", "TNI"],
                fetchModal      : false,                
                dataFetch       : false,
                dataList        : [],
                emptyState      : false,
                keywords        : "",
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 9,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                },
                dataDetail : {
                    content : ''
                }
            }
        },
        components: {
            vPagination
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'tutorial/list';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        category    : this.activeTabs,
                        keywords    : this.keywords,
                        page        : this.pagination.currentPage,
                        limit       : this.pagination.limit
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            goToView: function(content="") {
                this.fetchModal = true;
                setTimeout(() => {
                    this.dataDetail.content = content;
                    this.fetchModal = false;
                }, 500);
            },
            closeModalView: function() {
                setTimeout(() => {
                    this.dataDetail.content = '';
                }, 500);
            },
            changeTab: function(id) {
                this.activeTabs = id;
                this.getData();
            }
        }
    }
</script>
