<template>
    <div class="start-quiz">
        <div v-if="dataFetch">
            <p>Sedang memuat data soal...</p>
        </div>

        <div v-if="!dataFetch" class="row" id="block-questions">
            <div class="col-lg-8">
                <div class="page-header nocopy">
                    <div class="page-block">
                            <div class="page-header-title">
                                
                                <b style="font-size:18px" class="m-t-10">Soal Nomor #{{number_questions}}</b>
                                <div style="float:right">
                                    <button data-toggle="modal" data-target="#modalReport" type="button" class="btn btn-danger btn-sm rounded"><i class="fa fa-info-circle"></i> Laporkan Soal</button>
                                </div>
                                <hr/>
                                
                                <div class="m-b-20" v-html="form.questions"></div>
                                
                                <div v-if="form.attachment">
                                    <vue-plyr>
                                        <audio controls crossorigin playsinline>
                                            <source :src="form.attachment" type="audio/mp3" />
                                            <source :src="form.attachment" type="audio/ogg" />
                                        </audio>
                                    </vue-plyr>
                                    <hr />
                                </div>

                                <div v-if="form.type_questions == 'multiple_answer' && form.options_a" class="form-check">
                                    <input class="form-check-input input-primary" type="checkbox" id="options_a" name="options_a" true-value="a" false-value="" v-model="multipleAnswer" value="a" disabled="true">
                                    <label class="form-check-label" for="customCheckc1">
                                        <div v-html="form.options_a"></div>
                                    </label>
                                </div>      

                                <div v-if="form.type_questions == 'multiple_answer' && form.options_b" class="form-check">
                                    <input class="form-check-input input-primary" type="checkbox" id="options_b" name="options_b" true-value="b" false-value="" v-model="multipleAnswer" value="b" disabled="true">
                                    <label class="form-check-label" for="customCheckc1">
                                        <div v-html="form.options_b"></div>
                                    </label>
                                </div>

                                <div v-if="form.type_questions == 'multiple_answer' && form.options_c" class="form-check">
                                    <input class="form-check-input input-primary" type="checkbox" id="options_c" name="options_c" true-value="c" false-value="" v-model="multipleAnswer" value="c" disabled="true">
                                    <label class="form-check-label" for="customCheckc1">
                                        <div v-html="form.options_c"></div>
                                    </label>
                                </div>

                                <div v-if="form.type_questions == 'multiple_answer' && form.options_d" class="form-check">
                                    <input class="form-check-input input-primary" type="checkbox" id="options_d" name="options_d" true-value="d" false-value="" v-model="multipleAnswer" value="d" disabled="true">
                                    <label class="form-check-label" for="customCheckc1">
                                        <div v-html="form.options_d"></div>
                                    </label>
                                </div>
                                
                                <div v-if="form.type_questions == 'multiple_answer' && form.options_e" class="form-check">
                                    <input class="form-check-input input-primary" type="checkbox" id="options_e" name="options_e" true-value="e" false-value="" v-model="multipleAnswer" value="e" disabled="true">
                                    <label class="form-check-label" for="customCheckc1">
                                        <div v-html="form.options_e"></div>
                                    </label>
                                </div>

                                <div v-if="form.type_questions == 'multiple_answer'" class="card card-theory p-3 ">
                                    <b>Poin Soal</b>
                                    
                                    <div class="d-block mb-1 mt-2">
                                        <div v-for="(item, index) in form.point_options" :key="index"
                                        class="d-flex align-items-center justify-content-between pb-2 m-t-10 border-bottom"
                                        :class="form.your_answer == index ? 'font-weight-bold text-success':''"
                                        >
                                            <div class="mr-3">Point {{index.toUpperCase()}}</div>
                                            <div class="font-weight-600">{{item}}</div>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="form.type_questions == 'multiple_answer'">
                                    <div v-if="form.your_answer" class="d-flex card card-theory p-3 font-weight-bold">
                                        <span :class="form.status_answer == 'correct' ? 'text-success':'text-danger'">Jawaban Kamu : {{ form.your_answer.join(", ").toUpperCase() }}</span>
                                    </div>
                                </div>

                                <div v-if="form.type_questions == 'essay'">
                                    <div v-if="form.your_answer" class="d-flex card card-theory p-3 font-weight-bold">
                                        <span>Jawaban Kamu : {{ form.your_answer }}</span>
                                    </div>
                                </div>

                                <div v-if="form.type_questions == 'multiple_choice'" style="margin-top:-5px"
                                    class="d-flex"
                                    :class="form.correct_answer == 'a' ? 'font-weight-bold text-success': form.your_answer == 'a' ? 'font-weight-bold text-danger':''">
                                    <span class="m-r-10">A.</span>
                                    <span v-html="form.options_a"></span>
                                </div>

                                <div v-if="form.type_questions == 'multiple_choice'" style="margin-top:-5px"
                                    class="d-flex"
                                    :class="form.correct_answer == 'b' ? 'font-weight-bold text-success': form.your_answer == 'b' ? 'font-weight-bold text-danger':''">
                                    <span class="m-r-10">B.</span>
                                    <span v-html="form.options_b"></span>
                                </div>

                                <div v-if="form.type_questions == 'multiple_choice'" style="margin-top:-5px"
                                    class="d-flex"
                                    :class="form.correct_answer == 'c' ? 'font-weight-bold text-success': form.your_answer == 'c' ? 'font-weight-bold text-danger':''">
                                    <span class="m-r-10">C.</span>
                                    <span v-html="form.options_c"></span>
                                </div>

                                <div v-if="form.type_questions == 'multiple_choice'" style="margin-top:-5px"
                                    class="d-flex"
                                    :class="form.correct_answer == 'd' ? 'font-weight-bold text-success': form.your_answer == 'd' ? 'font-weight-bold text-danger':''">
                                    <span class="m-r-10">D.</span>
                                    <span v-html="form.options_d"></span>
                                </div>

                                <div v-if="form.type_questions == 'multiple_choice'" style="margin-top:-5px"
                                    class="d-flex"
                                    :class="form.correct_answer == 'e' ? 'font-weight-bold text-success': form.your_answer == 'e' ? 'font-weight-bold text-danger':''">
                                    <span class="m-r-10">E.</span>
                                    <span v-html="form.options_e"></span>
                                </div>
                                
                                <hr/>

                                <div v-if="(form.type_questions == 'multiple_choice' || form.type_questions == 'match')">
                                    <div class="d-flex card card-theory p-3 font-weight-bold">
                                        <span>Poin Soal : {{form.point}}</span>
                                    </div>

                                    <div class="d-flex card card-theory p-3 font-weight-bold">
                                        <span>Kunci Jawaban : {{form.correct_answer.toUpperCase()}}</span>
                                    </div>

                                    <div v-if="form.your_answer" class="d-flex card card-theory p-3 font-weight-bold">
                                        <span :class="form.status_answer == 'correct' ? 'text-success':'text-danger'">Jawaban Kamu : {{form.your_answer.toUpperCase()}}</span>
                                    </div>
                                </div>

                                <div v-if="form.type_questions == 'multiple_complex'">
                                    <div class="d-flex card card-theory p-3 font-weight-bold">
                                        <span >Jawaban Kamu : </span>

                                        <div class="table-responsive mt-2">
                                            <table style="font-weight: bold;  font-size:11px; width:100%; border-collapse: collapse; margin-top:10px;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;">
                                                <thead>
                                                    <tr class="text-center">
                                                        <th style="border: 1px solid #d1d1d1; text-align: left; padding: 10px; border-right: none; background-color: #f8f8f8; width: 60%">Pertanyaan</th>
                                                        <th style="border: 1px solid #d1d1d1; text-align: center; padding: 10px; border-right: none; background-color: #f8f8f8;">{{ form.description_column_one }}</th>
                                                        <th style="border: 1px solid #d1d1d1; text-align: center; padding: 10px; border-right: none; background-color: #f8f8f8;">{{ form.description_column_two }}</th>
                                                        <th style="border: 1px solid #d1d1d1; text-align: center; padding: 10px; background-color: #f8f8f8;">Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, index) in optionsComplex" :key="index">
                                                        <td style="border: 1px solid #d1d1d1; text-align: left; padding: 10px; border-right: none; " v-html="item">
                                                        </td>
                                                        <td style="border: 1px solid #d1d1d1; text-align: center; padding: 10px; border-right: none; ">
                                                            <input type="radio" :name="'answerComplex_'+index" v-model="answerComplex[index]" value="1" style="height:20px; width:20px; vertical-align: middle;" disabled="true">
                                                        </td>
                                                        <td style="border: 1px solid #d1d1d1; text-align: center; padding: 10px; border-right: none;">
                                                            <input type="radio" :name="'answerComplex_'+index" v-model="answerComplex[index]" value="0" style="height:20px; width:20px; vertical-align: middle;" disabled="true">
                                                        </td>
                                                        <td style="border: 1px solid #d1d1d1; text-align: center; padding: 10px; ">
                                                            {{ answerComplex[index] == answerCorrectComplex[index] ? '✔️':'❌'}}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    
                                    <div class="d-flex card card-theory p-3 font-weight-bold">
                                        <span >Kunci Jawaban : </span>

                                        <div class="table-responsive mt-2">
                                            <table style="font-weight: bold;  font-size:11px; width:100%; border-collapse: collapse; margin-top:10px;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;">
                                                <thead>
                                                    <tr class="text-center">
                                                        <th style="border: 1px solid #d1d1d1; text-align: left; padding: 10px; border-right: none; background-color: #f8f8f8; width: 60%">Pertanyaan</th>
                                                        <th style="border: 1px solid #d1d1d1; text-align: center; padding: 10px; border-right: none; background-color: #f8f8f8;">Poin</th>
                                                        <th style="border: 1px solid #d1d1d1; text-align: center; padding: 10px; border-right: none; background-color: #f8f8f8;">{{form.description_column_one}}</th>
                                                        <th style="border: 1px solid #d1d1d1; text-align: center; padding: 10px; background-color: #f8f8f8;">{{ form.description_column_two }}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, index) in optionsComplex" :key="index">
                                                        <td style="border: 1px solid #d1d1d1; text-align: left; padding: 10px; border-right: none; " v-html="item">
                                                        </td>
                                                        <td style="border: 1px solid #d1d1d1; text-align: center; padding: 10px; border-right: none; ">
                                                            {{ pointOptionsComplex[index] }}
                                                        </td>
                                                        <td style="border: 1px solid #d1d1d1; text-align: center; padding: 10px; border-right: none; ">
                                                            {{ answerCorrectComplex[index] == 1 ? '✔️':''}}
                                                        </td>
                                                        <td style="border: 1px solid #d1d1d1; text-align: center; padding: 10px; ">
                                                            {{ answerCorrectComplex[index] == 0 ? '✔️':''}}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="form.type_questions == 'match_making'">
                                    <div class="d-flex card card-theory p-3 font-weight-bold">
                                        <span >Jawaban Kamu : </span>

                                        <div class="table-responsive mt-2">
                                            <table style="font-weight: bold;  font-size:11px; width:100%; border-collapse: collapse; margin-top:10px;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;">
                                                <thead>
                                                    <tr class="text-center">
                                                        <th style="border: 1px solid #d1d1d1; text-align: left; padding: 10px; border-right: none; background-color: #f8f8f8; width: 60%">Pertanyaan</th>
                                                        <th style="border: 1px solid #d1d1d1; text-align: center; padding: 10px; border-right: none; background-color: #f8f8f8;">Pilihan</th>
                                                        <th style="border: 1px solid #d1d1d1; text-align: center; padding: 10px; background-color: #f8f8f8;">Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, index) in optionsComplex" :key="index">
                                                        <td style="border: 1px solid #d1d1d1; text-align: left; padding: 10px; border-right: none; " v-html="item">
                                                        </td>
                                                        <td style="border: 1px solid #d1d1d1; text-align: center; padding: 10px; border-right: none; ">
                                                            {{answerComplex[index]}}
                                                        </td>
                                                        <td style="border: 1px solid #d1d1d1; text-align: center; padding: 10px; ">
                                                            {{
                                                                cleanAnswer(answerComplex[index]) == cleanAnswer(answerCorrectComplex[index]) ? '✔️' : '❌'
                                                            }}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    
                                    <div class="d-flex card card-theory p-3 font-weight-bold">
                                        <span >Kunci Jawaban : </span>

                                        <div class="table-responsive mt-2">
                                            <table style="font-weight: bold;  font-size:11px; width:100%; border-collapse: collapse; margin-top:10px;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;">
                                                <thead>
                                                    <tr class="text-center">
                                                        <th style="border: 1px solid #d1d1d1; text-align: left; padding: 10px; border-right: none; background-color: #f8f8f8; width: 60%">Pertanyaan</th>
                                                        <th style="border: 1px solid #d1d1d1; text-align: center; padding: 10px; border-right: none; background-color: #f8f8f8;">Poin</th>
                                                        <th style="border: 1px solid #d1d1d1; text-align: center; padding: 10px; background-color: #f8f8f8;">Pilihan</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="(item, index) in optionsComplex" :key="index">
                                                        <td style="border: 1px solid #d1d1d1; text-align: left; padding: 10px; border-right: none; " v-html="item">
                                                        </td>
                                                        <td style="border: 1px solid #d1d1d1; text-align: center; padding: 10px; border-right: none; ">
                                                            {{ pointOptionsComplex[index] }}
                                                        </td>
                                                        <td style="border: 1px solid #d1d1d1; text-align: center; padding: 10px; ">
                                                            {{answerCorrectComplex[index]}}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <span v-if="!form.your_answer" class="d-flex p-3 alert alert-danger" role="alert">
                                    <b>Kamu tidak mengisi jawaban pada soal ini</b>
                                </span>

                                <div v-if="form.discussion" class="d-flex card card-theory p-3">
                                    <span><b>Pembahasan</b></span>
                                    <hr/>
                                    <div v-html="form.discussion"></div>
                                </div>
                                
                                <div v-if="form.discussion_video" class="d-flex card card-theory p-3">
                                    <span><b>Video Pembahasan</b></span>
                                    <hr/>
                                    <vue-plyr>
                                    <div class="plyr__video-embed">
                                        <iframe
                                        :src="form.discussion_video"
                                        allowfullscreen
                                        allowtransparency
                                        allow="autoplay"
                                        ></iframe>
                                    </div>
                                    </vue-plyr>
                                </div>

                                <div class="d-flex card card-theory p-3">
                                    <span><b>Analisis Waktu Mengerjakan</b></span>
                                    <hr/>
                                    <span v-if="form.your_answer" class="text-center"><i class="fa fa-clock"></i> Waktu yang anda habiskan untuk mengerjakan soal ini adalah :
                                    <b v-if="form.timer">{{form.timer}}</b>
                                    <b v-if="!form.timer">1 detik</b>
                                    </span>
                                    <span v-if="!form.your_answer" class="d-flex p-3 text-center alert alert-danger" role="alert">
                                        Analisis waktu tidak ditemukan karena kamu tidak menjawab soal ini.
                                    </span>
                                </div>

                                <hr/>
                                <div class="row">
                                    <div class="col-md-12 d-flex justify-content-center flex-wrap vertical-align-center">
                                        <button class="btn btn-outline-primary rounded m-r-10" type="button" v-on:click="prev(number_questions)"> <i class="fa fa-arrow-left"></i> Soal Sebelumnya</button>
                                        <button class="btn btn-outline-primary rounded" type="button" v-on:click="next(number_questions)" >Soal Selanjutnya <i class="fa fa-arrow-right"></i></button>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="page-header">
                    <div class="page-block">
                        <div class="page-header-title">
                            <b style="font-size:15px" class="m-t-10">Nomor Soal</b>
                            <hr/>
                            <div class="d-block text-center" style="max-height:230px;overflow: auto;">
                                <button style="width:100%;max-width: 55px;" v-for="(item, index) in dataList" :key="index"
                                    type="button"
                                    class="text-center btn rounded m-l-10 m-t-10" v-on:click="setQuestions(item.id, item.number)"
                                    :class="item.btn_style"
                                    >{{item.number}} 
                                </button>                                    
                            </div>
                        </div>
                    </div>
                </div>
                <div class="page-header">
                    <div class="page-block">
                        <div class="page-header-title">
                            <b style="font-size:15px" class="m-t-10">Keterangan Warna</b>
                            <hr/>
                            <div class="d-block mb-1 mt-2">
                                <div class="d-flex align-items-center justify-content-between pb-2 m-t-10 border-bottom">
                                    <div class="font-weight-600 mr-2">
                                        Benar
                                    </div>
                                    <div class="font-weight-600">
                                        <span class="btn btn-success rounded">&nbsp;&nbsp;&nbsp;</span>
                                    </div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2 m-t-10 border-bottom">
                                    <div class="font-weight-600 mr-2">
                                        Salah
                                    </div>
                                    <div class="font-weight-600">
                                        <span class="btn btn-danger rounded">&nbsp;&nbsp;&nbsp;</span>
                                    </div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2 m-t-10 border-bottom">
                                    <div class="font-weight-600 mr-2">
                                        Tidak Mengisi
                                    </div>
                                    <div class="font-weight-600">
                                        <span class="btn btn-outline-primary rounded">&nbsp;&nbsp;&nbsp;</span>
                                    </div>
                                </div>

                                <div class="d-flex align-items-center justify-content-between pb-2 m-t-10 border-bottom">
                                    <div class="font-weight-600 mr-2">
                                        Belum Dinilai
                                    </div>
                                    <div class="font-weight-600">
                                        <span class="btn btn-secondary rounded">&nbsp;&nbsp;&nbsp;</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal" id="modalReport" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between text-sm">
                            <div class="font-weight-600" style="font-size:20px"><i class="fa fa-info-circle"></i> Laporkan Soal</div>
                            <div>
                                <button id="btnCloseModal" type="button" class="btn btn-link btn-sm p-0 text-danger"
                                    data-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>

                    <form v-on:submit.prevent="onSubmit">
                        <div class="d-block p-3">
                            <div class="d-flex align-items-center pb-3 row">
                                <div class="col-md-12">
                                    <div class="m-t-10">
                                        <label>Kategori Laporan</label>
                                        <select class="form-control" id="exampleFormControlSelect1" v-model="formReport.report_category_id" :class="{ 'is-invalid': submitted && $v.formReport.report_category_id.$error }">
                                            <option value="">Pilih Kategori</option>
                                            <option v-for="(item, index) in dataCategory" :key="index" :value="item.id">{{item.name}}</option>
                                        </select>
                                        <div v-if="submitted && !$v.formReport.report_category_id.required" class="invalid-feedback">Kategori wajib diisi!</div>    
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="m-t-10">
                                        <label>Pesan</label>
                                        <textarea class="form-control" v-model="formReport.description" :class="{ 'is-invalid': submitted && $v.formReport.description.$error }" rows="4" placeholder="Masukan laporan kamu.."></textarea>
                                        <div v-if="submitted && !$v.formReport.description.required" class="invalid-feedback">Pesan wajib diisi!</div>    
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button :disabled="waiting" type="submit" class="btn btn-primary btn-block"><i class="fa fa-paper-plane"></i> {{ waiting ? 'Sedang mengirim laporan...' : 'Kirim Laporan' }}</button>
                    </form>
                </div>
            </div>
        </div>        
    </div>
</template>

<style scoped>
    .start-quiz ::v-deep img {
        max-width: 100%!important;
        height: auto!important;
    }

    .start-quiz ::v-deep .nocopy {
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;    
    }

    @media screen and (max-width: 767px) {
        .start-quiz ::v-deep img {
            max-width: 100%!important;
            height: auto!important;
        }
    }

    .start-quiz ::v-deep .btn-complete, .btn-complete:focus {
        color: #fff;
        background-color: #008000 !important;
        border-color: #008000 !important;
    }

    .start-quiz ::v-deep .btn-complete.active {
        color: #fff;
        background-color: #04bd04 !important;
        border-color: #000 !important;
    }
</style>

<script>
    import {
        required
    } from "vuelidate/lib/validators";

    export default {
        name: 'StartQuiz',
        metaInfo: {
            title: 'Pembahasan Soal Latihan CBT',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                dataFetch   : true,
                dataList    : {
                    exercises : {
                        list_questions : []
                    }
                },
                form : {
                    exercises_answer       : '',
                    questions           : '',
                    type_questions      : '',
                    point_options       : '',
                    options_a           : '',
                    options_b           : '',
                    options_c           : '',
                    options_d           : '',
                    options_e           : '',
                    your_answer         : '',
                    exercises_question_id  : '',
                    correct_answer      : '',
                    level               : '',
                    discussion          : '',
                    discussion_video    : '',
                    sub_category        : '',
                    timer               : '',
                    status_answer       : '',
                    description_column_one : '',
                    description_column_two : '',
                    attachment : ''
                },
                currentQuestions: "",
                currentSaveQuestions : [],
                currentSaveAnswer    : [],
                changeAnswer         : [],
                multipleAnswer       : [],
                optionsComplex       : [],
                answerComplex        : [],
                answerCorrectComplex : [],
                pointOptionsComplex  : [],
                formReport: {
                    report_category_id : '',
                    description : ''
                },
                submitted       : false,
                waiting         : false,
                dataCategory    : []
            }
        },
        created() {
            this.getData();
            this.getCategory();
        },
        validations: {
            formReport: {
                report_category_id: {
                    required
                },
                description: {
                    required
                }
            }
        },
        methods : {
            cleanAnswer(answer) {
                if (typeof answer !== 'string') {
                    answer = String(answer);
                }
                return answer.trim().replace(/\s+/g, ' ');
            },            
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/cbt/exercises/result/pembahasan';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        result_id        : this.$route.params.result_id,
                        cbt_exercises_answer_id : this.$route.params.cbt_exercises_answer_id
                    }
                }).then(res => {
                    if(res.data.status) {
                        let getResponse = res.data;
                        this.dataList   = getResponse.data;

                        this.setQuestions(this.dataList[0].id, 1);
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({
                                    name: 'home'
                                });
                            }
                        });
                    }

                    this.dataFetch = false;
                });
            },
            setQuestions: function(id, number) {
                this.multipleAnswer      = [];
                this.answerComplex       = [];

                var item = this.dataList.find(item => item.id === id);
                this.currentQuestions    = id;
                this.number_questions    = number;

                this.form.exercises_question_id    = item.id;
                this.form.sub_category          = item.sub_category;
                this.form.questions             = item.question;
                this.form.point                 = item.point;
                this.form.point_options         = item.point_options;
                this.form.type_questions        = item.type_questions;
                this.form.correct_answer        = item.correct_answer;
                this.form.discussion            = item.discussion;
                this.form.status_answer         = item.status_answer;
                this.form.discussion_video      = "";             
                this.form.attachment            = "";

                setTimeout(() => {
                    if(item.attachment != null) {
                        this.form.attachment        = item.attachment;
                    }
                    this.form.discussion_video      = item.discussion_video;                    
                }, 100);
                
                this.form.timer                 = item.timer;

                if(this.form.type_questions == 'multiple_complex' || this.form.type_questions == 'match_making') {
                    this.optionsComplex = item.options;
                } else {
                    this.form.options_a             = item.options.a;
                    this.form.options_b             = item.options.b;
                    this.form.options_c             = item.options.c;
                    this.form.options_d             = item.options.d;
                    this.form.options_e             = item.options.e;
                }

                this.form.level                 = item.level;                

                if(this.changeAnswer[item.id]) {
                    this.changeAnswer[item.id]  = this.currentSaveAnswer[item.id];
                    this.form.your_answer       = this.currentSaveAnswer[item.id];
                }
                
                else {
                    this.form.your_answer           = item.your_answer;
                }

                if(this.form.your_answer) {
                    this.currentSaveQuestions[item.id]  = true;
                    this.currentSaveAnswer[item.id]     = this.form.your_answer;

                    if(item.type_questions == 'multiple_answer') {
                        this.multipleAnswer = this.currentSaveAnswer[item.id];
                    }

                    if(item.type_questions == 'multiple_complex' || item.type_questions == 'match_making') {
                        this.answerComplex = this.currentSaveAnswer[item.id];
                    }
                }

                if(item.type_questions == 'multiple_complex' || item.type_questions == 'match_making') {
                    this.answerCorrectComplex = item.answer_options;
                    this.pointOptionsComplex = item.point_options;
                }

                this.formReport.report_category_id  = "";
                this.formReport.description         = "";

                this.form.description_column_one    = item.description_column_one;
                this.form.description_column_two    = item.description_column_two;
                this.scrollToTop();
            },
            scrollToTop() {
                window.scrollTo({top: 400, behavior: 'smooth'});
            },
            next: function(number="") {
                let next_number     = number+1;
                let total_questions = this.dataList.length;

                if(next_number > total_questions) {
                    alert("Kamu sudah berada di soal terakhir");
                } else {
                    let id              = this.dataList[number].id;
                    this.setQuestions(id, next_number);
                }
            },
            prev: function(number="") {
                let next_number     = number-1;

                if(next_number < 1) {
                    alert("Kamu sudah berada di soal pertama");
                } else {
                    let id              = this.dataList[next_number-1].id;
                    this.setQuestions(id, next_number);
                }
            },
            getCategory: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/report/category';
                this.$http.get(uri).then(res => {
                    this.dataCategory   = res.data.data;
                });
            },
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    this.$http.post(process.env.VUE_APP_APIHOST + 'member/report/send', {
                        report_category_id  : this.formReport.report_category_id,
                        description         : this.formReport.description,
                        number_questions    : this.number_questions,
                        entity_id           : this.currentQuestions,
                        title               : this.$parent.$parent.dataDetail.statistik.title
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Laporan berhasil dikirim!',
                                html: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    document.getElementById("btnCloseModal").click();
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: response.data.message,
                            });
                        }

                        this.waiting    = false;
                        this.submitted  = false;

                    })
                }
            }            
        }
    }
</script>
    