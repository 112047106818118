<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-b-10">{{label}} Tutorial</h5>
                        </div>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link :to="{name : 'home'}"><i class="feather icon-home"></i></router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="{name : 'tutorial'}">Tutorial</router-link>
                            </li>
                            <li class="breadcrumb-item"><a href="javascript:void(0)">Form</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="page-header">
            <div class="page-block">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <form v-on:submit.prevent="onSubmit">
                            <div class="row">
                                <input type="hidden" name="id" v-model="form.id">
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Judul</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form.title" :class="{ 'is-invalid': submitted && $v.form.title.$error }">
                                    <div v-if="submitted && !$v.form.title.required" class="invalid-feedback">wajib diisi!</div>    
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Deskripsi</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form.description">
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">URL Video (<small>Contoh: https://www.youtube.com/embed/1Rf_ULpQhIw</small>)</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form.url_video" :class="{ 'is-invalid': submitted && $v.form.url_video.$error }">
                                    <div v-if="submitted && !$v.form.url_video.required" class="invalid-feedback">wajib diisi!</div>    
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Yang bisa melihat tutorial? (Guru/Peserta/Proktor)</label>
                                    <multiselect
                                        v-model="form.list_role" tag-placeholder="Add this as new tag"
                                        placeholder="Pilih data" label="name" track-by="id" :options="list_access"
                                        :multiple="true" :taggable="true"></multiselect>

                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Kategori (CPNS/Kedinasan/SNBT/PPPK/AKM/English Academy/CBT/Polri/TNI)</label>
                                    <multiselect
                                        v-model="form.list_category" tag-placeholder="Add this as new tag"
                                        placeholder="Pilih data" label="name" track-by="id" :options="list_category"
                                        :multiple="true" :taggable="true"></multiselect>

                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleFormControlSelect1">Status</label>
                                    <select class="form-control" id="exampleFormControlSelect1" v-model="form.status">
                                        <option value="publish">Publish</option>
                                        <option value="draft">Draft</option>
                                    </select>
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Cover</label>
                                    <input type="file" class="form-control" name="cover" id="cover" @change="previewFiles" accept="image/png, image/gif, image/jpeg">
                                </div>

                                <div class="form-group col-md-6" v-if="preview_cover">
                                    <label for="exampleInputEmail1">Preview cover</label>
                                    <div class="thumbnail mb-4">
                                        <div class="thumb">
                                            <a :href="preview_cover" data-lightbox="1" target="blank">
                                                <img :src="preview_cover" alt="" class="img-fluid img-thumbnail" style="width:50%">
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-primary m-r-10" :disabled="waiting">
                            <i class="fa fa-save"></i> {{ waiting ? 'Harap tunggu ...' : 'Simpan' }}</button>
                            <router-link :to="{name : 'tutorial'}" class="btn btn-outline-primary"><i class="fa fa-back"></i> Kembali</router-link>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        required
    } from "vuelidate/lib/validators";
    
    export default {
        name: 'TutorialForm',
        metaInfo: {
            title: 'Form Tutorial',
            titleTemplate: '%s - Griya Belajar'
        },
        created() {
            this.list_access    = []
            this.list_category  = []
            this.data_access.forEach(element => {
                let name = "Guru";
                if(element == 'content') {
                    name = "Guru";
                }

                if(element == 'member') {
                     name = "Peserta";
                }

                if(element == 'proctor') {
                     name = "Proktor";
                }
                this.list_access.push({
                    id: element,
                    name: name
                });
            });

            this.data_category.forEach(element => {
                this.list_category.push({
                    id: element,
                    name: element
                });
            });            
            this.getDataByID(this.$route.params.id);
        },
        data() {
            return {
                label           : 'Tambah',
                preview_cover   : '',
                data_access     : ['content','member','proctor'],
                data_category   : ["CPNS", "Kedinasan", "SNBT", "PPPK", "AKM", "English Academy", "CBT", "Polri", "TNI"],
                list_access     : [],
                form : {
                    id              : "",
                    title           : "",
                    status          : "draft",
                    description     : "",
                    cover           : "",
                    url_video       : "",
                    list_role       : [],
                    list_category   : []
                },
                submitted       : false,
                waiting         : false
            }
        },
        validations: {
            form: {
                title: {
                    required
                },
                url_video: {
                    required
                }
            }
        },
        methods: {
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {   
                    if(this.form.list_role.length == 0) {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Harap pilih hak akses tutorial',
                        });

                        this.waiting = false;
                        return
                    } else {
                        let formData = new FormData();

                        formData.append('id', this.form.id);
                        formData.append('title', this.form.title);
                        formData.append('description', this.form.description);
                        formData.append('status', this.form.status);
                        formData.append('url_video', this.form.url_video);
                        formData.append('cover', this.form.cover);
                        formData.append('list_role', JSON.stringify(this.form.list_role));
                        formData.append('list_category', JSON.stringify(this.form.list_category));

                        this.$http.post(process.env.VUE_APP_APIHOST + 'admin/tutorial/save', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        })
                        .then(response => {
                                if (response.data.status) {
                                    this.$swal({
                                        icon: 'success',
                                        title: 'Success',
                                        text: response.data.message,
                                        confirmButtonText: 'OK'
                                    }).then((result) => {
                                        if (result.value) {
                                            this.$router.push({ name: 'tutorial' });
                                        }
                                    });
                                } else {
                                    this.$swal({
                                        icon: 'warning',
                                        title: 'Ups!',
                                        text: response.data.message,
                                    });
                                }
                                this.waiting = false;
                            })
                        .catch(function () {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: 'Please check your internet connection.',
                            });
                            this.waiting = false;
                            return;
                        });
                    }
                }
            },
            getDataByID: function(id) {
                if (typeof (id) !== 'undefined' && id !== null) {
                    this.label  = "Ubah";

                    let uri     = process.env.VUE_APP_APIHOST+'admin/tutorial/detail';

                    this.dataFetch  = true;                
                    this.$http.get(uri,{
                        params : {
                            id : id
                        }
                    }).then(res => {
                        if(res.data.status) {
                            let getResponse     = res.data.data;
                            
                            this.form           = getResponse;
                            this.form.cover     = getResponse.cover;
                            this.preview_cover  = getResponse.cover;

                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: res.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({ name: 'tutorial' });
                                }
                            });
                        }
                    });
                }
            },
            previewFiles(e) {
                const file = e.target.files[0];                
                var fsize = ((file.size/1024)/1024).toFixed(4); // MB
                
                if(fsize >= 5) {
                    alert("File yang di upload tidak boleh lebih 5 MB");
                } else {
                    this.preview_cover = URL.createObjectURL(file);
                    this.form.cover = file;
                }
            }
        }
    }
</script>