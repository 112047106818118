<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10">Pengaturan Jadwal Presensi Harian</h5>
                            <div class="float-right" style="margin-top:-65px">
                                <a href="javascript:void(0)" class="btn btn-primary rounded" data-toggle="modal" data-target="#modal_form_setup_presensi" v-on:click="clearData()">Tambah Data</a>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan kata kunci..." v-model="keywords" v-on:keyup.enter="getByKeywords">
                                </div>

                                <div v-if="dataFetch">
                                    <p>Sedang mengambil data...</p>
                                </div>

                                <div v-if="!dataFetch" class="table-responsive m-t-20">
                                    <div v-if="dataList.length > 0" >
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th style="width:5%" class="text-center">No</th>
                                                    <th style="width:10%">Nama Jadwal</th>
                                                    <th style="width:10%">Kelas</th>
                                                    <th style="width:10%">Periode</th>
                                                    <th style="width:10%">Jam Masuk</th>
                                                    <th style="width:10%">Toleransi Keterlambatan</th>
                                                    <th style="width:5%">Aksi</th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="dataList.length > 0">
                                                <tr v-for="(item, index) in dataList" :key="index">
                                                    <td class="text-center">
                                                        {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                                    </td>
                                                    <td>{{item.schedule_name}}<br/>
                                                        <small>Tipe Jadwal: Kelas <b>{{ item.type == 'morning' ? 'Pagi':'Siang' }}</b></small>
                                                    </td>
                                                    <td>
                                                        <ul v-if="item.school_class" style="margin-bottom: 0px; margin-left: 0px;">
                                                            <li v-for="(itemDetail, indexDetail) in item.school_class" :key="indexDetail">{{ itemDetail.name }}</li>
                                                        </ul>
                                                    </td>
                                                    <td>{{item.start_date}} s/d {{ item.end_date }}</td>
                                                    <td>{{item.clock_in}}</td>
                                                    <td>{{item.tolerance}} Menit</td>
                                                    <td>
                                                        <a href="javascript:void(0)" class="btn btn-primary btn-sm rounded m-r-10" data-toggle="modal" data-target="#modal_form_setup_presensi" v-on:click="editData(item)"><i class="fas fa-edit"></i> Ubah</a>
                                                        <button v-on:click="deleteData(item.id)" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Hapus" type="button" class="btn btn-danger btn-sm rounded "><i class="fas fa-trash"></i> Hapus</button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <nav aria-label="navigation" class="m-t-20">
                                            <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                                        </nav>
                                    </div>

                                    <div v-if="emptyState">
                                        <empty-state></empty-state>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal" id="modal_form_setup_presensi" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-lg modal-dialog modal-dialog-centered">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0">
                        <div class="d-flex align-items-center justify-content-between text-sm">
                            <div class="font-weight-600" style="font-size:20px">{{form.id ? 'Ubah':'Tambah'}} Jadwal</div>
                            <div>
                                <button type="button" id="closeModal" class="btn btn-link btn-sm p-0 text-danger"
                                    data-dismiss="modal">Tutup</button>
                            </div>
                        </div>
                    </div>
                    <div class="d-block p-3 m-t-10">
                        <form v-on:submit.prevent="onSubmit">
                            <div class="form-group row">
                                <label for="inputEmail3" class="col-sm-3 col-form-label">Nama Jadwal<span class="text-danger">*</span></label>
                                <div class="col-sm-9">
                                    <input type="text" class="form-control" id="inputEmail3" placeholder="Masukan nama Jadwal..." v-model="form.schedule_name" autocomplete="off">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="inputEmail3" class="col-sm-3 col-form-label">Tanggal Mulai<span class="text-danger">*</span></label>
                                <div class="col-sm-9">
                                    <input type="date" class="form-control" id="inputEmail3" placeholder="Masukan tanggal mulai..." v-model="form.start_date" autocomplete="off">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="inputEmail3" class="col-sm-3 col-form-label">Tanggal Selesai<span class="text-danger">*</span></label>
                                <div class="col-sm-9">
                                    <input type="date" class="form-control" id="inputEmail3" placeholder="Masukan tanggal selesai..." v-model="form.end_date" autocomplete="off">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="inputEmail3" class="col-sm-3 col-form-label">Jam Masuk<span class="text-danger">*</span></label>
                                <div class="col-sm-9">
                                    <input type="time" class="form-control" id="inputEmail3" placeholder="Masukan tanggal selesai..." v-model="form.clock_in" autocomplete="off">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="inputEmail3" class="col-sm-3 col-form-label">Toleransi Keterlambatan<span class="text-danger">*</span></label>
                                <div class="col-sm-9">
                                    <input type="number" min="0" class="form-control" id="inputEmail3" placeholder="Masukan toleransi keterlambatan (dalam menit)..." v-model="form.tolerance" autocomplete="off">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="inputEmail3" class="col-sm-3 col-form-label">Kelas<span class="text-danger">*</span></label>
                                <div class="col-sm-9">
                                    <multiselect
                                        v-model="form.school_class" tag-placeholder="Add this as new tag"
                                        placeholder="Pilih Kelas" label="id" track-by="name" :options="dataClass"
                                        :multiple="true" :taggable="true"></multiselect>
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="inputEmail3" class="col-sm-3 col-form-label">Tipe Jadwal<span class="text-danger">*</span></label>
                                <div class="col-sm-9">
                                    <select class="form-control" v-model="form.type">
                                        <option value="morning">Kelas Pagi</option>
                                        <option value="afternoon">Kelas Siang</option>
                                    </select>
                                </div>
                            </div>

                            <small>Keterangan:<br/>(<span class="text-danger">*</span>) Form Wajib diisi</small>
                            <hr/>
                            <button type="submit" class="btn btn-primary rounded btn-block" :disabled="waiting">
                            <i class="fa fa-save"></i> {{ waiting ? 'Harap tunggu ...' : 'Simpan' }}</button>
                         </form>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'

    export default {
        name: 'SubjectsGeneral',
        metaInfo: {
            title: 'Pengaturan Jadwal Presensi Harian',
            titleTemplate: '%s - Griya Belajar'
        },
        components: {
            vPagination
        },
        data() {
            return {
                waiting         : false,
                emptyState      : false,
                dataFetch       : true,
                dataList        : [],
                keywords        : "",
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                },
                form : {
                    id : '',
                    schedule_name : '',
                    school_id: '',
                    school_class: [],
                    start_date: '',
                    end_date: '',
                    clock_in: '',
                    tolerance: '',
                    type: 'morning'                  
                },
                dataUsers: [],
                dataSchool: [],
                dataClass: []
            }
        },
        created() {
            this.getOverview();
        },
        methods: {
            getOverview: function() {
                let uri         = process.env.VUE_APP_APIHOST+'user/info';
                this.$http.get(uri).then(res => {
                    this.dataUsers   = res.data;

                    this.getSchool();
                });
            },
            getClass: function() {
                this.form.school_id = this.dataUsers.school_id;
                this.dataClass = [];
                this.form.school_class = [];
                this.dataSchool.forEach(element => {
                    if (element.id == this.dataUsers.school_id) {
                        this.dataClass = element.data_class;
                        this.dataClass = this.dataClass.map(item => {
                            const trimmedItem = item.trim();
                            return {
                                id: trimmedItem,
                                name: trimmedItem
                            };
                        });
                    }
                });

                this.getData();
            },
            getSchool: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/members/school';
                this.$http.get(uri).then(res => {
                    this.dataSchool   = res.data.data;

                    this.getClass();
                });
            },
            getByKeywords: function() {
                this.pagination.currentPage = 1;
                this.getData();
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/daily-schedule-setting/list';
                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        keywords  : this.keywords,
                        page      : this.pagination.currentPage,
                        limit     : this.pagination.limit
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            deleteData: function (id) {
                this.$swal({
                icon: 'info',
                title: 'Hapus Data',
                text: 'Apakah kamu yakin ingin menghapus data ini?',
                confirmButtonText: 'Hapus',
                showCancelButton: true,
                cancelButtonText: 'Batal',
                }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/daily-schedule-setting/delete', {
                        id: id
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getData();
                                }
                            });
                        } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                        }
                    })
                }
                });
            },
            clearData: function() {
                this.form.id = '';
                this.form.schedule_name = '';
                this.form.school_class = [];
                this.form.start_date = '';
                this.form.end_date = '';
                this.form.clock_in = '';
                this.form.tolerance = '';
                this.form.type = 'morning';
            },
            editData: function(item) {
                this.form.id = item.id;
                this.form.schedule_name = item.schedule_name;
                this.form.school_class = item.school_class;
                this.form.start_date = item.start_date;
                this.form.end_date = item.end_date;
                this.form.clock_in = item.clock_in;
                this.form.tolerance = item.tolerance;
                this.form.type = item.type;
            },
            onSubmit: function() {
                console.log(this.form);
                if(this.form.schedule_name == '' || this.form.school_id == '' || this.form.school_class.length <= 0 || this.form.type == '' || this.form.start_date == '' || this.form.end_date == '') {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: "Harap lengkapi form!",
                    });

                } else {
                    let formData = new FormData();

                    formData.append('id', this.form.id);
                    formData.append('schedule_name', this.form.schedule_name);
                    formData.append('school_id', this.form.school_id);
                    formData.append('school_class', JSON.stringify(this.form.school_class));
                    formData.append('start_date', this.form.start_date);
                    formData.append('end_date', this.form.end_date);
                    formData.append('clock_in', this.form.clock_in);
                    formData.append('tolerance', this.form.tolerance);
                    formData.append('type', this.form.type);

                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/daily-schedule-setting/save', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    document.getElementById("closeModal").click();
                                    this.getData();
                                }
                            });
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                html: response.data.message,
                            });
                        }
                        this.waiting = false;
                    })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });
                }
            }
        }
    }
</script>