<template>
    <div class="page-header">
        <div class="row align-items-center">
            <div class="col-md-12">
                <div class="page-header-title">
                    <h5 class="m-t-10">Nilai & Statistik Hasil Tryout</h5>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 m-t-20" v-for="(item, indexOne) in this.$parent.$parent.dataDetail.list_component" :key="indexOne">
                <div class="card card-theory h-100">
                    <div class="card-body h-100">
                        <h4 class="job-card-desc">
                            <b><small class="font-weight-600">{{item.name}}</small></b>
                        </h4>
                        <hr />
                        <div class="d-block mt-4">
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3 font-weight-600" style="width:60%">Nama</div>
                                <div class="font-weight-600 text-right" style="width:10%">
                                    Skor
                                </div>
                            </div>

                            <div v-for="(itemDetail, indexDetail) in item.list" :key="++indexDetail"
                                class="d-flex align-items-center justify-content-between pb-2"
                                :class="indexDetail != item.list.length ? 'mb-3 border-bottom':''">
                                <div class="mr-3 font-weight-600" style="width:60%">
                                    {{itemDetail.sub_category}}
                                </div>
                                <div class="font-weight-400 text-right" style="width:10%">
                                    {{itemDetail.score}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 m-t-20" v-for="(itemAnswer) in this.$parent.$parent.dataDetail.statistic_answer" :key="itemAnswer.name">
                <div class="card card-theory h-100">
                    <div class="card-body h-100">
                        <h4 class="job-card-desc text-center">
                            Statistik Jawaban<br/>
                            <small>{{itemAnswer.name}}</small>
                        </h4>
                        <div class="d-block mt-4">
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3 font-weight-bold">Jawaban Benar</div>
                                <div class="font-weight-600">
                                {{itemAnswer.correct}}
                                </div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3 font-weight-bold">Jawaban Salah</div>
                                <div class="font-weight-600">
                                {{itemAnswer.wrong}}
                                </div>
                            </div>
                            <div class="d-flex align-items-center justify-content-between pb-2">
                                <div class="mr-3 font-weight-bold">Tidak Mengisi</div>
                                <div class="font-weight-600">
                                {{itemAnswer.empty}}
                                </div>
                            </div>
                        </div>
                        <div>
                            <apexchart type="pie" :options="chartOptionsDetail" :series="itemAnswer.data"></apexchart>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-12 m-t-20" :class="this.$parent.$parent.dataDetail.statistic_answer.length > 1 ? '':'d-none'">
                <div class="card card-theory h-100">
                    <div class="card-body h-100">
                        <h4 class="job-card-desc text-center">
                            Grafik Nilai
                        </h4>

                        <div>
                            <apexchart type="bar" :options="chartOptions" :series="series"></apexchart>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'StatisticTryoutAsn',
        metaInfo: {
            title: 'Nilai dan Statistik Nilai Tryout',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                chartOptions: {
                    colors: ['#008FFB', '#00E396', '#FEB019', '#35858B', '#04293A', '#E9B2BC', '#95CD41', '#AE431E',
                        '#B4C6A6'
                    ],
                    xaxis: {
                        categories: ['Grafik Hasil Nilai']
                    },
                },
                series: this.$parent.$parent.dataDetail.series,
                chartOptionsDetail: {
                    chart: {
                        type: 'pie',
                    },
                    colors: ['#2ed8b6', '#ff5370', '#F78812'],
                    labels: ['Jawaban Benar', 'Jawaban Salah', 'Tidak Mengisi'],
                    responsive: [{
                        breakpoint: 480,
                        options: {
                            chart: {
                                width: 200
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }]
                }
            }
        },
        methods: {
        }
    }
</script>