<template>
    <div>
        <div v-if="dataFetch" class="page-header">
            <div class="d-flex align-items-center justify-content-between">
                <div class="mr-3 w-100">
                    <div class="d-flex align-items-center">
                        <div class="w-100">
                            <div class="skeleton-bar">
                                <div class="sb-sm m-b-2"></div>
                                <hr/>
                                <div class="sb-sm sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-md sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>

                                <div class="sb-md sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-2"></div>
                                <div class="sb-lg sb-thin mb-4"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!dataFetch" class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10"><a href="javascript:void(0)" onclick="window.history.back();"><i class="fa fa-arrow-left m-r-10"></i></a> Persiapan Tryout</h5>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-8">
                        <div class="d-block mb-1 mt-2">
                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Nama Tryout</div>
                                <div class="font-weight-600">{{dataDetail.title}}</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Total Waktu</div>
                                <div class="font-weight-600">{{dataDetail.timer}} Menit</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Jumlah Keseluruhan Soal</div>
                                <div class="font-weight-600">{{dataDetail.total_questions}} Soal</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom">
                                <div class="mr-3">Komponen Soal</div>
                            </div>

                            <div class="d-flex align-items-center justify-content-between pb-2 mb-3 border-bottom row">
                                <div class="mr-3 col-md-12" v-for="(item, index) in dataDetail.list_component" :key="index">
                                    <b>{{item.name}}</b>
                                    <ol class="m-t-10">
                                        <li v-for="(itemDetail, indexDetail) in item.list" :key="indexDetail">{{itemDetail.sub_category}} <i class="ml-2 fa fa-arrow-right mr-2"></i> Waktu mengerjakan <b>{{itemDetail.timer}} Menit </b> ({{itemDetail.total_questions}} Soal)</li>
                                    </ol>
                                </div>
                            </div>

                            <div class="alert alert-info m-t-10" role="alert">
                                <b>Perhatian !</b>
                                <hr/>
                                <ol>
                                    <li>Gunakan browser versi terbaru supaya website dapat diakses dengan lancar tanpa masalah.</li>
                                    <li>Pastikan tidak ada aktivitas login ke akun anda (pada perangkat lain) saat sedang mengerjakan tryout</li>
                                    <li>Tryout ini <b>hanya berlaku 1x</b>, kerjakan dengan hati - hati dan periksa kembali jawaban yang telah kamu isi.</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <button v-if="!dataDetail.answer" type="button" data-toggle="modal" data-target="#instructionQuiz" class="btn btn-primary rounded btn-block m-t-10"><i class="fa fa-edit"></i> Mulai mengerjakan</button>
                        <router-link v-if="dataDetail.answer" :to="{name : 'member_tryout_asn_result_detail', params: { result_id: dataDetail.answer_id, tryout_answer_id: dataDetail.tryout_answer_id }}" class="btn btn-primary rounded btn-block m-t-10"><i class="fa fa-bars"></i> Lihat Hasil</router-link>
                        <a href="javascript:void(0)" onclick="window.history.back();" class="btn btn-outline-primary rounded btn-block m-t-10"><i class="fa fa-back"></i> Kembali</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal" id="instructionQuiz" tabindex="-1" aria-hidden="true" data-backdrop="static">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style="overflow: hidden;">
                    <div class="d-block px-3 pt-3 pb-0 text-center">
                        <div class="align-items-center justify-content-between text-sm">
                            <img style="width: 30%; height: auto;" class="mb-5"
                                src="https://scolacdn.com/frontend/images/sign-warning-icon.png" />
                            <h5>Untuk lanjut ke pengerjaan soal mohon diperhatikan beberapa langkah berikut: </h5>
                        </div>
                    </div>
                    <div class="d-block p-3">
                    <ol class="mb-4">
                        <li>Pastikan internet stabil.</li>
                        <li>Pastikan tidak ada aktivitas login ke akun anda (pada perangkat lain) saat sedang mengerjakan tryout.</li>
                        <li>Disarankan menggunakan versi browser terbaru.</li>
                        <li>Kerjakan soal sesuai dengan waktu yang ditentukan.</li>
                        <li>Pastikan semua jawaban terisi</li>
                        <li>Tryout ini <b>hanya berlaku 1x</b>, kerjakan dengan hati - hati dan periksa kembali jawaban yang telah kamu isi.</li>
                    </ol>
                        <button data-dismiss="modal" v-on:click="startTyout" type="button" class="btn btn-primary rounded btn-block m-t-10"><i class="fa fa-edit"></i> Mulai</button>
                    </div>
                </div>
            </div>
        </div>
     </div>
</template>

<script>
    import {
        required
    } from "vuelidate/lib/validators";
    import "vue-select/dist/vue-select.css";

    export default {
        name: 'TryoutAsnOverview',
        metaInfo: {
            title: 'Persiapan Tryout',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                submitted : false,
                waiting : false,
                form : {
                    collage : '',
                    collage_two : '',
                    program_study : '',
                    program_study_two : '',
                    collage_three : '',
                    program_study_three : '',
                    collage_four : '',
                    program_study_four : ''
                },
                dataFetch       : false,
                dataDetail      : [],
                emptyState      : false,
                randomCode      : (Math.random() + 1).toString(36).substring(2),
                listCollage         : [],
                listProgramStudy    : [],
                listProgramStudyTwo : [],
                listProgramStudyThree : [],
                listProgramStudyFour : [],
            }
        },
        created() {
            this.getData();
            this.getCollage();
        },
        validations: {
            form: {
                collage: {
                    required
                },
                program_study: {
                    required
                }
            }
        },
        methods: {
            getCollage: function () {
                let uri = process.env.VUE_APP_APIHOST+'member/collage/list';
                
                this.$http.get(uri).then(res => {
                    let getResponse = res.data;
                    this.listCollage = getResponse.data;
                    this.getProgramStudy();
                    this.getProgramStudyTwo();
                    this.getProgramStudyThree();
                    this.getProgramStudyFour();
                });
            },
            getProgramStudy: function() {
                this.listProgramStudy = [];
                
                if(this.form.collage) {
                    if(this.form.collage.id) {
                        let uri = process.env.VUE_APP_APIHOST+'member/collage/detail';
                        this.$http.get(uri, {
                            params: {
                                id : this.form.collage.id,
                                program : this.dataDetail.program
                            }
                        }
                        ).then(res => {
                            let getResponse = res.data;
                            this.listProgramStudy = getResponse.data;
                        });
                    }
                }
            },
            getProgramStudyTwo: function() {
                this.listProgramStudyTwo = [];
                
                if(this.form.collage_two) {
                    if(this.form.collage_two.id) {
                        let uri = process.env.VUE_APP_APIHOST+'member/collage/detail';
                        this.$http.get(uri, {
                            params: {
                                id : this.form.collage_two.id,
                                program : this.dataDetail.program
                            }
                        }
                        ).then(res => {
                            let getResponse = res.data;
                            this.listProgramStudyTwo = getResponse.data;
                        });
                    }
                }
            },
            getProgramStudyThree: function() {
                this.listProgramStudyThree = [];
                
                if(this.form.collage_three) {
                    if(this.form.collage_three.id) {
                        let uri = process.env.VUE_APP_APIHOST+'member/collage/detail';
                        this.$http.get(uri, {
                            params: {
                                id : this.form.collage_three.id,
                                program : this.dataDetail.program
                            }
                        }
                        ).then(res => {
                            let getResponse = res.data;
                            this.listProgramStudyThree = getResponse.data;
                        });
                    }
                }
            },
            getProgramStudyFour: function() {
                this.listProgramStudyFour = [];
                
                if(this.form.collage_four) {
                    if(this.form.collage_four.id) {
                        let uri = process.env.VUE_APP_APIHOST+'member/collage/detail';
                        this.$http.get(uri, {
                            params: {
                                id : this.form.collage_four.id,
                                program : this.dataDetail.program
                            }
                        }
                        ).then(res => {
                            let getResponse = res.data;
                            this.listProgramStudyFour = getResponse.data;
                        });
                    }
                }
            },
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'member/asn/tryout/detail';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        slug        : this.$route.params.slug
                    }
                }).then(res => {
                    if(res.data.status) {
                        this.dataDetail   = res.data.data;
                        if(!this.dataDetail.owned) {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: "Akses tidak diperbolehkan! Anda belum memiliki tryout ini",
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({
                                        name: 'home'
                                    });
                                }
                            });
                        } else {
                            if(this.dataDetail.ongoing) {
                                if(this.dataDetail.break) {
                                    this.$router.push({
                                        name: 'member_tryout_asn_break',
                                        params : {
                                            slug    : this.$route.params.slug
                                        }
                                    });
                                } else {
                                    this.$router.push({
                                        name: 'member_tryout_asn_start',
                                        params: {
                                            slug        : this.$route.params.slug,
                                            id          : this.dataDetail.tryout_detail.data.id
                                        }
                                    });
                                }
                            } else {
                                if(this.dataDetail.finished) {
                                    this.$router.push({
                                        name: 'member_tryout_asn_result'
                                    });
                                } else {
                                    this.dataFetch  = false;         
                                }
                            }
                        }
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: res.data.message,
                            confirmButtonText: 'OK'
                        }).then((result) => {
                            if (result.value) {
                                this.$router.push({
                                    name: 'home'
                                });
                            }
                        });
                    }
                });
            },
            startTyout: function() {
                this.$http.post(process.env.VUE_APP_APIHOST + 'member/asn/tryout/start', {
                    slug        : this.$route.params.slug,
                    token       : this.randomCode,
                })
                .then(response => {
                    if (response.data.status) {
                        const result = response.data.data;

                        this.$router.push({
                            name: 'member_tryout_asn_start',
                            params: {
                                slug            : this.$route.params.slug,
                                id              : result.id
                            }
                        });
                    } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                    }
                })
            },
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    this.$swal({
                        icon: 'info',
                        title: 'Informasi',
                        html: '<p>Untuk lanjut ke pengerjaan soal mohon diperhatikan beberapa langkah berikut:<br/>'+
                        '<p>'+
                        '1. Pastikan internet stabil.<br/>'+
                        '2. Disarankan menggunakan versi browser terbaru.<br/>'+
                        '3. Kerjakan soal sesuai dengan waktu yang ditentukan.<br/>'+
                        '4. Pastikan semua jawaban terisi.'+
                        '</p>',
                        confirmButtonText: 'Mulai Mengerjakan',
                        showCancelButton: true,
                        cancelButtonText: 'Batal',
                        }).then((result) => {
                        if (result.value) {
                            this.$http.post(process.env.VUE_APP_APIHOST + 'member/asn/tryout/start', {
                                slug        : this.$route.params.slug,
                                token       : this.randomCode,
                                collage         : this.form.collage,
                                program_study   : this.form.program_study,
                                collage_two     : this.form.collage_two,
                                program_study_two   : this.form.program_study_two,
                                collage_three     : this.form.collage_three,
                                program_study_three   : this.form.program_study_three,
                                collage_four     : this.form.collage_four,
                                program_study_four   : this.form.program_study_four
                            })
                            .then(response => {
                                if (response.data.status) {
                                    const result = response.data.data;

                                    document.getElementById("btnCloseModal").click();
                                    this.$router.push({
                                        name: 'member_tryout_asn_start',
                                        params: {
                                            slug        : this.$route.params.slug,
                                            id          : result.id
                                        }
                                    });
                                } else {
                                    this.$swal({
                                        icon: 'warning',
                                        title: 'Ups!',
                                        text: response.data.message,
                                    });
                                }
                            })
                        }
                    });
                }
            }
        }
    }
</script>