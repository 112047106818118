<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-t-10">Data Materi TNI/POLRI/BUMN/BUMN</h5>
                            <div class="float-right" style="margin-top:-65px">
                                <router-link class="btn btn-primary rounded" :to="{name : 'asn_theory_add'}">Tambah Data</router-link>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <button v-for="(item, index) in data_type_asn" :key="index" class="btn rounded m-r-10" :class="activeTabs == item ? 'btn-primary':'btn-outline-primary' " v-on:click="changeTab(item)" type="button">{{item == 'Semua Kategori' ? item:'Kategori '+item}}</button>
                                <div class="input-group mt-3">
                                    <input type="text" autocomplete="off" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Cari data berdasarkan kata kunci..." v-model="keywords" v-on:keyup.enter="getData">
                                    <div class="input-group-append">
                                        <button v-on:click="clearFilter" class="btn waves-effect waves-light btn-primary btn-icon btn-msg-send" type="button"><i
                                                class="fa fa-search"></i> Cari </button>
                                    </div>
                                </div>

                                <div v-if="dataFetch" class="m-t-20">
                                    <p>Sedang mengambil data...</p>
                                </div>

                                <div v-if="!dataFetch" class="table-responsive m-t-20">
                                    <div v-if="dataList.length > 0">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th style="width:5%" class="text-center">No</th>
                                                    <th style="width:10%">Kategori</th>
                                                    <th>Judul</th>
                                                    <th>Tipe</th>
                                                    <th style="width:10%">Cover</th>
                                                    <th class="text-center">Status</th>
                                                    <th style="width:15%">Aksi</th>
                                                </tr>
                                            </thead>
                                            <tbody v-if="dataList.length > 0">
                                                <tr v-for="(item, index) in dataList" :key="index">
                                                    <td class="text-center">
                                                        {{ (index + 1) + (pagination.currentPage - 1) * pagination.limit }}
                                                    </td>
                                                    <td>
                                                        <span v-if="item.type_asn == 'tni'" class="badge badge-success">TNI</span>
                                                        <span v-if="item.type_asn == 'polri'" class="badge badge-primary">POLRI</span>
<span v-if="item.type_asn == 'bumn'" class="badge badge-info">BUMN</span>
                                                    </td>
                                                    <td>
                                                        {{item.title}}<br/>
                                                        <small>{{ item.description }}</small>
                                                    </td>
                                                    <td>
                                                        <span v-if="item.type == 'youtube' " class="badge badge-danger">Youtube / Video</span>
                                                        <span v-if="item.type == 'text' " class="badge badge-info">Text</span>
                                                    </td>
                                                    <td v-if="!item.cover">-</td>
                                                    <td v-if="item.cover">
                                                        <div class="thumbnail mb-4">
                                                            <div class="thumb">
                                                                <a :href="item.cover" data-lightbox="1" target="blank">
                                                                    <img :src="item.cover" alt="" class="img-fluid img-thumbnail" style="width:60px">
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="text-center">
                                                        <span v-if="item.status == 'publish' " class="badge badge-success">Publish</span>
                                                        <span v-if="item.status == 'draft' " class="badge badge-secondary">Draft</span>
                                                    </td>
                                                    <td>
                                                        <router-link :to="{name : 'asn_theory_edit', params: { id: item.id }}" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" type="button" class="btn btn-primary btn-sm rounded m-r-10"><i class="fas fa-edit"></i> Ubah</router-link>
                                                        <button v-on:click="deleteData(item.id)" data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Hapus" type="button" class="btn btn-danger btn-sm rounded"><i class="fas fa-trash"></i> Hapus</button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody v-if="dataList.length < 1">
                                                <tr colspan="6">
                                                    <td>Data tidak ditemukan</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                    <div v-if="emptyState">
                                        <empty-state></empty-state>
                                    </div>
                                </div>

                                <nav v-if="(!dataFetch && dataList.length > 0)" aria-label="navigation" class="m-t-20">
                                    <v-pagination v-model="pagination.currentPage" :page-count="pagination.totalPages" :classes="pagination.bootstrapPaginationClasses" :labels="pagination.paginationAnchorTexts" v-on:change="getData()"></v-pagination>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import vPagination from 'vue-plain-pagination'

    export default {
        name: 'AsnTheory',
        metaInfo: {
            title: 'Data Materi TNI/POLRI/BUMN/BUMN',
            titleTemplate: '%s - Griya Belajar'
        },
        components: {
            vPagination
        },
        data() {
            return {
                emptyState      : false,
                dataFetch       : false,
                dataList        : [],
                keywords        : "",
                data_type_asn   : ["Semua Kategori", "TNI","Polri","BUMN"],
                type_asn        : "",
                activeTabs      : 'Semua Kategori',
                pagination: {
                    currentPage : 1,
                    totalPages  : 0,
                    limit       : 10,
                    bootstrapPaginationClasses: {
                        ul: 'pagination justify-content-end',
                        li: 'page-item',
                        liActive: 'active',
                        liDisable: 'disabled',
                        button: 'page-link'
                    },
                    paginationAnchorTexts: {
                        first: 'First',
                        prev: 'Previous',
                        next: 'Next',
                        last: 'Last'
                    }
                }
            }
        },
        created() {
            this.getData();
        },
        methods: {
            getData: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/asn/theory/list';

                this.dataFetch  = true;                
                this.$http.get(uri,{
                    params : {
                        keywords : this.keywords,
                        type_asn : this.type_asn,
                        page     : this.pagination.currentPage,
                        limit    : this.pagination.limit
                    }
                }).then(res => {
                    this.emptyState             = false;
                    this.dataFetch              = false;                
                    this.dataList               = res.data.data;
                    this.pagination.totalPages  = res.data.total_page;

                    if(this.dataList.length < 1) {
                        this.emptyState = true;
                    }
                });
            },
            deleteData: function (id) {
                this.$swal({
                icon: 'info',
                title: 'Hapus Data',
                text: 'Apakah kamu yakin ingin menghapus data ini?',
                confirmButtonText: 'Hapus',
                showCancelButton: true,
                cancelButtonText: 'Batal',
                }).then((result) => {
                if (result.value) {
                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/asn/theory/delete', {
                        id: id
                    })
                    .then(response => {
                        if (response.data.status) {
                            this.$swal({
                                icon: 'success',
                                title: 'Success',
                                text: response.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.getData();
                                }
                            });
                        } else {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: response.data.message,
                        });
                        }
                    })
                }
                });
            },
            changeTab: function(id) {
                this.activeTabs = id;

                if(id != 'Semua Kategori') {
                    this.type_asn = id;
                } else {
                    this.type_asn = "";
                }

                this.clearFilter();
            },
            clearFilter: function() {
                this.pagination.currentPage = 1;
                this.getData();
            }
        }
    }
</script>
