<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-b-10">Sinkronisasi Sistem</h5>
                        </div>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link :to="{name : 'home'}"><i class="feather icon-home"></i></router-link>
                            </li>
                            <li class="breadcrumb-item"><a href="javascript:void(0)">Sinkronisasi Sistem</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" style="margin-top:-10px !important">
            <div class="col-sm-12">
                <div class="card card-hover-none">
                    <div class="card-header">
                        <h5>Atur Sinkronisasi</h5>
                    </div>
                    <div class="card-body">
                        <form v-on:submit.prevent="onSubmit">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Pilih Tipe Sinkronisasi</label>
                                        <div class="col-sm-6">
                                            <select class="form-control" v-model="sync_option">
                                                <option value="">Pilih Tipe Sinkronisasi</option>
                                                <option value="sync/cbt-tryout-answer">Tryout CBT</option>
                                                <option value="sync/cbt-exercises-answer">Latihan CBT</option>
                                                <option value="sync/testimonial">Gambar Testimoni</option>
                                                <option value="sync/cover-cbt-tryout">Cover Tryout CBT</option>
                                                <option value="sync/cbt-tryout-ongoing">Tryout CBT (Sedang Mengerjakan)</option>
                                                <option value="sync/cover-skd-theory">Cover Materi CPNS + Kedinasan</option>
                                            </select>
                                        </div>
                                        <div class="col-sm-3">
                                            <input type="number" class="form-control" v-model="sync_limit" placeholder="masukan limit...">
                                        </div>
                                    </div>

                                    <div class="form-group row" v-if="show_total">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Jumlah Berhasil digenerate</label>
                                        <div class="col-sm-9">
                                            <span>{{ total_generate }}</span>
                                        </div>
                                    </div>

                                    <div class="form-group row" v-if="show_total">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Waktu Terakhir Generate</label>
                                        <div class="col-sm-9">
                                            <span>{{ current_time }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <button v-on:click="startSyncProcess()" type="button" class="btn btn-outline-primary rounded btn-block" :disabled="waiting"> <i class="fa fa-check"></i> {{ waiting ? 'Sedang memproses...' : 'Sinkronisasi' }}</button>
                            <button v-if="waiting" v-on:click="stopSyncProcess()" type="button" class="btn btn-danger rounded btn-block"> <i class="fa fa-stop"></i> Hentikan Proses</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SyncSystems',
        metaInfo: {
            title: 'Sinkronisasi System',
            titleTemplate: '%s - Griya Belajar'
        },
        data() {
            return {
                stopProcess     : false,
                submitted       : false,
                waiting         : false,
                sync_option     : '',
                sync_limit      : 10,
                show_total      : false,
                total_generate  : 0,
                current_time    : 0,
            }
        },
        methods: {  
            syncProcess: function() {
                if (this.stopProcess) {
                    this.waiting = false;
                    this.$swal({
                        icon: 'warning',
                        title: 'Proses Dihentikan!',
                        text: 'Sinkronisasi telah dihentikan.',
                    });
                    return;
                }      

                if(this.sync_option) {
                    this.waiting = true;

                    this.$http.get(process.env.VUE_APP_APIHOST+this.sync_option+'?limit='+this.sync_limit, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                    })
                    .then(response => {
                        if(response.data.status) {
                            this.show_total     = true;
                            this.total_generate = (this.total_generate+response.data.data);
                            this.current_time = response.data.current_time;
                            this.syncProcess();
                        } else {
                            this.waiting = false;
                            this.$swal({
                                icon: 'success',
                                title: 'Berhasil!',
                                text: 'Semua data telah selesai digenerate!',
                            });
                        }
                    })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });                        
                } else {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap pilih tipe sinkronisasi!',
                    });
                }
            },
            stopSyncProcess: function() {
                this.stopProcess = true;
            },
            startSyncProcess: function() {
                this.stopProcess = false;
                this.syncProcess();
            }            
        }
    }
</script>