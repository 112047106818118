<template>
    <div>
        <div class="alert alert-info alert-dismissible fade show d-flex" role="alert" v-if="dataFetchSchool">
            <strong><i class="fa fa-info-circle"></i></strong>
                <span class="m-l-10">Sedang memuat...
            </span>
        </div>
        
        <div class="alert alert-info alert-dismissible fade show d-flex" role="alert" v-if="!dataFetchSchool">
            <strong><i class="fa fa-info-circle"></i></strong>
                <span class="m-l-10">Saat ini kamu terdaftar sebagai guru dari sekolah <b>{{ dataSchool.name }}</b>.                
            </span>
        </div>

        <div class="page-header" v-if="!dataFetchSchool">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-b-10">Rekapan Kehadiran</h5>
                        </div>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link :to="{name : 'home'}"><i class="feather icon-home"></i></router-link>
                            </li>
                            <li class="breadcrumb-item"><a href="javascript:void(0)">Rekapan Kehadiran</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" style="margin-top:-10px !important" v-if="!dataFetchSchool">
            <div class="col-sm-12">
                <div class="card card-hover-none">
                    <div class="card-header">
                        <h5>Atur Kelas & Tanggal Kehadiran</h5>
                    </div>
                    <div class="card-body">
                        <form v-on:submit.prevent="onSubmit">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Pilih Kelas</label>
                                        <div class="col-sm-9">
                                            <multiselect
                                                v-model="school_class" tag-placeholder="Add this as new tag"
                                                placeholder="Pilih data" label="name" track-by="id" :options="data_class"
                                                :multiple="true" :taggable="true"></multiselect>
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Dari Tanggal</label>
                                        <div class="col-sm-9">
                                            <input type="date" class="form-control" id="inputEmail3" placeholder="Masukan tanggal awal..."  v-model="start_date">
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="inputEmail3" class="col-sm-3 col-form-label">Hingga Tanggal</label>
                                        <div class="col-sm-9">
                                            <input type="date" class="form-control" id="inputEmail3" placeholder="Masukan tanggal akhir..." v-model="end_date">
                                        </div>
                                    </div>

                                    <div class="form-group row">
                                        <label for="status_present" class="col-sm-3 col-form-label">Status Kehadiran</label>
                                        <div class="col-sm-9">
                                            <div class="form-check">
                                                <input class="form-check-input input-primary" type="checkbox" id="all_status" v-model="selectAll" @change="toggleAll">
                                                <label class="form-check-label" for="all_status" style="margin-top: 2px !important;">Semua Status</label>
                                            </div>

                                            <div class="form-check" v-for="(status, index) in statusOptions" :key="index">
                                                <input class="form-check-input input-primary" type="checkbox" :id="status.value" v-model="status.selected" @change="checkIndividual">
                                                <label class="form-check-label" :for="status.value" style="margin-top: 2px !important;">{{ status.label }}</label>
                                            </div>
                                        </div>
                                    </div>                                    
                                </div>
                            </div>
                            
                            <div class="alert alert-warning alert-dismissible fade show d-flex" role="alert">
                                <strong><i class="fa fa-info-circle"></i></strong>
                                    <span class="m-l-10">Pilih kelas dan tanggal diatas untuk mendownload Rekapan Kehadiran. Setelah itu tekan tombol 'Download Laporan' dibawah ini..</span>
                            </div>

                            <button v-on:click="recapProcess()" type="button" class="btn btn-outline-primary rounded btn-block" :disabled="waiting"> <i class="fa fa-calendar-check"></i> {{ waiting ? 'Sedang memproses...' : 'Download Laporan (Excel)' }}</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ScheduleRecap',
        metaInfo: {
            title: 'Rekapan Kehadiran Absensi',
            titleTemplate: '%s - Griya Belajar'
        },
        created() {
            this.getSchool();
        },
        data() {
            return {
                submitted       : false,
                waiting         : false,
                dataFetchSchool : false,
                dataSchool      : [],
                school_class    : '',
                start_date      : '',
                end_date        : '',
                status_present  : 'ALL',
                selectAll: true,
                statusOptions: [
                    { label: 'Hadir', value: 'H', selected: false },
                    { label: 'Izin', value: 'I', selected: false },
                    { label: 'Sakit', value: 'S', selected: false },
                    { label: 'Alpha', value: 'A', selected: false }
                ],
                data_class : []            
            }
        },
        methods: {
            getSchool: function() {
                let uri         = process.env.VUE_APP_APIHOST+'admin/attendance/school-info';
                this.dataFetchSchool  = true;                
                this.$http.get(uri).then(res => {
                    this.dataFetchSchool    = false;                
                    this.dataSchool         = res.data.data;

                    this.data_class = [];
                    this.dataSchool.list_class.forEach(element => {
                        this.data_class.push({
                            id: element,
                            name: element
                        });
                    });
                });
            },
            recapProcess: function() {
                if(this.school_class.length > 0 && this.start_date && this.end_date) {
                    if(this.end_date < this.start_date) {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Tanggal akhir tidak boleh kurang dari tanggal awal.',
                        });
                    } else {
                        this.waiting = true;
                        let formData = new FormData();

                        formData.append('school_class', JSON.stringify(this.school_class));
                        formData.append('start_date', this.start_date);
                        formData.append('end_date', this.end_date);
                        
                        let selectedStatus = this.statusOptions.filter(status => status.selected).map(status => status.value);
                        formData.append('attendance_status', selectedStatus);

                        this.$http.post(process.env.VUE_APP_APIHOST + 'admin/attendance/schedule-recap-excel-v2', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            },
                            responseType: 'blob'
                        })
                        .then(response => {
                            if(response.data.type == 'application/json') {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: "Tidak ada data presensi!",
                                });       
                            } else {
                                const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/octet-stream' }));
                                const link = document.createElement('a');
                                link.href = url;
                                link.setAttribute('download', `${'Rekap Absensi - '+this.start_date+'_'+this.end_date}.xlsx`);
                                document.body.appendChild(link);
                                link.click();
                            }

                            this.waiting = false;
                        })
                        .catch(function () {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: 'Please check your internet connection.',
                            });
                            this.waiting = false;
                            return;
                        });                        
                    }
                } else {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap pilih kelas & rentang tanggal!',
                    });
                }
            },
            toggleAll() {
                this.statusOptions.forEach(status => {
                    status.selected = this.selectAll;
                });
            },
            checkIndividual() {
                this.selectAll = this.statusOptions.every(status => status.selected);
            }            
        }
    }
</script>