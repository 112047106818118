<template>
    <div>
        <div class="page-header">
            <div class="page-block">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <div class="page-header-title">
                            <h5 class="m-b-10">{{label}} Paket SNBT</h5>
                        </div>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item">
                                <router-link :to="{name : 'home'}"><i class="feather icon-home"></i></router-link>
                            </li>
                            <li class="breadcrumb-item">
                                <router-link :to="{name : 'utbk_package'}">Paket SNBT</router-link>
                            </li>
                            <li class="breadcrumb-item"><a href="javascript:void(0)">Form</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="page-header">
            <div class="page-block">
                <div class="row justify-content-start">
                    <div class="col-md-12">
                        <form v-on:submit.prevent="onSubmit">
                            <div class="row">
                                <input type="hidden" name="id" v-model="form.id">
                                <input type="hidden" name="type" v-model="form.type">


                                <div class="form-group col-md-12">
                                    <label for="exampleFormControlSelect1">Jurusan</label>
                                    <select class="form-control" id="exampleFormControlSelect1" v-model="form.program">
                                        <option value="">Pilih Jurusan</option>
                                        <option value="saintek">Saintek</option>
                                        <option value="soshum">Soshum</option>
                                    </select>
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Nama</label>
                                    <input type="text" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form.name" :class="{ 'is-invalid': submitted && $v.form.name.$error }">
                                    <div v-if="submitted && !$v.form.name.required" class="invalid-feedback">Judul wajib diisi!</div>    
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="exampleFormControlSelect1">Tipe</label>
                                    <select class="form-control" id="exampleFormControlSelect1" v-model="form.type_package" :class="{ 'is-invalid': submitted && $v.form.type_package.$error }">
                                        <option value="">Pilih Tipe</option>
                                        <option value="free">Free (Gratis)</option>
                                        <option value="premium">Premium (Berbayar)</option>
                                    </select>
                                    <div v-if="submitted && !$v.form.type_package.required" class="invalid-feedback">Tipe wajib diisi!</div>    
                                </div>
                                <div class="form-group col-md-12">
                                    <label for="exampleInputEmail1">Deskripsi Singkat</label>
                                    <textarea class="form-control" rows="4" placeholder="Masukan Deskripsi" v-model="form.description"></textarea>
                                </div>
                                <div class="form-group col-md-6" v-if="form.type_package == 'premium'">
                                    <label for="exampleInputEmail1">Harga Paket</label>
                                    <input type="number" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form.price">
                                </div>
                                <div class="form-group col-md-6" v-if="form.type_package == 'premium'">
                                    <label for="exampleInputEmail1">Harga Coret</label>
                                    <input type="number" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan data..." v-model="form.price_out">
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="exampleFormControlSelect1">Akses Materi</label>
                                    <select class="form-control" id="exampleFormControlSelect1" v-model="form.access_theory">
                                        <option value="1">Diperbolehkan</option>
                                        <option value="0">Tidak Diperbolehkan</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="exampleFormControlSelect1">Akses Latihan</label>
                                    <select class="form-control" id="exampleFormControlSelect1" v-model="form.access_exercises">
                                        <option value="1">Diperbolehkan</option>
                                        <option value="0">Tidak Diperbolehkan</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="exampleFormControlSelect1">Status</label>
                                    <select class="form-control" id="exampleFormControlSelect1" v-model="form.status">
                                        <option value="publish">Publish</option>
                                        <option value="draft">Draft</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Durasi Paket (hari)</label>
                                    <input type="number" class="form-control" id="exampleInputEmail1"
                                        placeholder="Masukan hari (Jika tidak disi, waktu default adalah 365 hari)" v-model="form.active_days" :class="{ 'is-invalid': submitted && $v.form.active_days.$error }">
                                    <div v-if="submitted && !$v.form.active_days.required" class="invalid-feedback">Durasi wajib diisi!</div>
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1 mr-3">Benefit Paket</label>
                                    <div v-for="(row, index) in form.description_other" :key="index">
                                        <div class="d-flex mb-3">
                                            <input type="text" class="form-control mb-0 mr-2" v-model="form.description_other[index]" rows="5" placeholder="Masukan benefit"/>
                                            <div class="flex-shrink-0">
                                                <button type="button" class="btn btn-danger" v-on:click="deleteFormDescOther(index)"><i class="fa fa-trash" data-toggle="tooltip" data-placement="top" data-title="Hapus"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                    <hr/>
                                    <button type="button" class="btn btn-outline-primary rounded" v-on:click="addFormDescOther()"><i class="fa fa-plus mr-2"></i>Tambah Benefit</button>
                                </div>

                                <div class="form-group col-md-6">
                                    <label for="exampleInputEmail1">Tryout</label>
                                    <multiselect
                                        v-model="form.tryout_id" tag-placeholder="Add this as new tag"
                                        placeholder="Pilih Tryout" label="title" track-by="id" :options="list_tryout"
                                        :multiple="true" :taggable="true"></multiselect>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-primary m-r-10" :disabled="waiting">
                            <i class="fa fa-save"></i> {{ waiting ? 'Harap tunggu ...' : 'Simpan' }}</button>
                            <router-link :to="{name : 'utbk_package'}" class="btn btn-outline-primary"><i class="fa fa-back"></i> Kembali</router-link>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        required
    } from "vuelidate/lib/validators";
    
    export default {
        name: 'Materi',
        metaInfo: {
            title: 'Form package UTBK',
            titleTemplate: '%s - Griya Belajar'
        },
        created() {
            this.getDataTryout();
            this.getDataByID(this.$route.params.id);
        },
        data() {
            return {
                label           : 'Tambah',
                list_tryout     : [],
                form : {
                    id              : "",
                    name           : "",
                    type_package    : "",
                    status          : "draft",
                    type            : "utbk",
                    price           : "",
                    price_out       : "",
                    active_days     : "",
                    access_theory       : 1,
                    access_exercises    : 1,
                    description         : "",
                    tryout_id           : "",
                    program             : "",
                    description_other   : [],
                },
                submitted       : false,
                waiting         : false,
            }
        },
        validations: {
            form: {
                name: {
                    required
                },
                type_package: {
                    required
                },
                status: {
                    required
                },
                active_days: {
                    required
                }
            }
        },
        methods: {
            onSubmit: function () {
                this.waiting    = true;
                this.submitted  = true;

                // stop here if form is invalid
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$swal({
                        icon: 'warning',
                        title: 'Ups!',
                        text: 'Harap lengkapi form',
                    });

                    this.waiting = false;
                    return;
                } else {    
                    let formData = new FormData();

                    formData.append('id', this.form.id);
                    formData.append('name', this.form.name);
                    formData.append('type_package', this.form.type_package);
                    formData.append('status', this.form.status);
                    formData.append('type', this.form.type);
                    formData.append('price', this.form.price);
                    formData.append('price_out', this.form.price_out);
                    formData.append('active_days', this.form.active_days);
                    formData.append('access_theory', this.form.access_theory);
                    formData.append('access_exercises', this.form.access_exercises);
                    formData.append('description', this.form.description);
                    formData.append('description_other', JSON.stringify(this.form.description_other));
                    formData.append('tryout_id', JSON.stringify(this.form.tryout_id));
                    formData.append('program', this.form.program);

                    this.$http.post(process.env.VUE_APP_APIHOST + 'admin/package/save', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                            if (response.data.status) {
                                this.$swal({
                                    icon: 'success',
                                    title: 'Success',
                                    text: response.data.message,
                                    confirmButtonText: 'OK'
                                }).then((result) => {
                                    if (result.value) {
                                        this.$router.push({ name: 'utbk_package' });
                                    }
                                });
                            } else {
                                this.$swal({
                                    icon: 'warning',
                                    title: 'Ups!',
                                    text: response.data.message,
                                });
                            }
                            this.waiting = false;
                        })
                    .catch(function () {
                        this.$swal({
                            icon: 'warning',
                            title: 'Ups!',
                            text: 'Please check your internet connection.',
                        });
                        this.waiting = false;
                        return;
                    });

                }
            },
            getDataByID: function(id) {
                if (typeof (id) !== 'undefined' && id !== null) {
                    this.label  = "Ubah";

                    let uri     = process.env.VUE_APP_APIHOST+'admin/package/detail';

                    this.dataFetch  = true;                
                    this.$http.get(uri,{
                        params : {
                            id : id,
                            type : 'utbk'
                        }
                    }).then(res => {
                        if(res.data.status) {
                            let getResponse             = res.data.data;
                            
                            this.form.id                = getResponse.id;
                            this.form.status            = getResponse.status;
                            this.form.name              = getResponse.name;
                            this.form.type_package      = getResponse.type_package;
                            this.form.type              = getResponse.type;
                            this.form.price             = getResponse.price;
                            this.form.price_out         = getResponse.price_out;
                            this.form.active_days       = getResponse.active_days;
                            this.form.description       = getResponse.description;
                            this.form.description_other = getResponse.description_other;
                            this.form.access_theory     = getResponse.access_theory;
                            this.form.access_exercises  = getResponse.access_exercises;
                            this.form.tryout_id         = getResponse.list_tryout;                            
                            this.form.program           = getResponse.program;                            
                        } else {
                            this.$swal({
                                icon: 'warning',
                                title: 'Ups!',
                                text: res.data.message,
                                confirmButtonText: 'OK'
                            }).then((result) => {
                                if (result.value) {
                                    this.$router.push({ name: 'utbk_package' });
                                }
                            });
                        }
                    });
                }
            },
            addFormDescOther() {
                this.form.description_other.push('');
            },
            deleteFormDescOther(index) {
                this.form.description_other.splice(index, 1);
            },
            getDataTryout: function () {
                let uri = process.env.VUE_APP_APIHOST + 'admin/package/tryout';

                this.$http.get(uri, {
                    params : {
                        type_tryout : this.form.type
                    }
                }).then(res => {
                    let getResponse = res.data;
                    if (getResponse.status) {
                        this.list_tryout = getResponse.data;
                    } else {
                        this.list_tryout = [];
                    }
                });
            },
        }
    }
</script>